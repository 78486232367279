@charset "utf-8";

@c_black: #000000;
@c_darkgrey: #272727;
@c_grey: #4f4f4f;
@c_white: #ffffff;
@c_lightgrey: #5d5d5d;
@c_red: #a71f23;
@c_marsala: #955251;

html,
body
{
	height: 100%;
	max-width: 1440px;
}

body
{
	font: normal 12px/16px Lato, Calibri, Tahoma, Arial, Helvetica, sans-serif;
	color: #000000;
	background: #ffffff;
	margin: 0;
	padding: 0;

	> header,
	> section,
	> footer 
	{
		position: relative;
		min-width: 1020px;
		margin: 0 auto 0 auto;
		text-align: center;
	}
	
	h2
	{
		font-weight: 300;
		font-size: 60px;
		line-height: 62px;
		color: @c_grey;
		padding-top: 128px;
	}
	
	header
	{
		
		height: 905px;
		background: url("../img/background-01.jpg") no-repeat center center;
		background-attachment: fixed; 
		
		z-index: 1;

		h1
		{
			overflow: hidden;
			text-indent: -4321px;
			margin: 0;
		}
		
		> p
		{
			position: absolute;
			top: 40px;
			left: 190px;
			height: 77px;
			width: 133px;
			overflow: hidden;
			background: url("../img/carmen-logo.png") no-repeat center center;
			z-index: 5;
			text-indent: -4321px;
		}
		
		nav
		{
			position: absolute;
			top: 84px;
			right: 204px;
			
			ul
			{
				li
				{
					color: @c_darkgrey;
					position: relative;
					display: inline-block;
					font-size: 16px;
					padding: 0 14px 0 14px;
					
					a
					{
						text-decoration: none;
						color: @c_darkgrey;
						&:hover
						{
						color: @c_marsala;
						}	
					}
				}
			}
		}
	}

	section 
	{
		&#oferta
		{
			h2
			{
				margin-bottom: 100px;
			}
			
			> div
			{
				width: 45%;
				display: inline-block;
				padding: 2%;
				vertical-align: top;
				
				img
				{

				}

				h3
				{
					font-size: 30px;
					font-weight: 700px;
					color: @c_black;
				}

				p
				{
					padding: 0 12%;
					font-size: 18px;
					line-height: 27px;
					font-weight: 400px;
					color: @c_lightgrey;
				}
			}
		}
		
		&#pedagodzy
		{
			background: #f1f1f1;
			z-index: 1;
			
			> div
			{
				position: relative;
				margin: 100px 250px 0 586px;
				text-align: left;
				padding-bottom: 100px;
				
				img
				{
					position: absolute;
					left: -274px;
					z-index: 10;
				}

				h3
				{
					font-size: 24px;
					font-weight: 700px;
					color: @c_black;
				}

				p.speciality
				{
					font-size: 24px;
					font-weight: 300px;
					color: #5d5d5d;
				}
				
				div.description
				{
					height: 170px;
					overflow: hidden;
					-webkit-transition: all 2s; /* Safari */
					transition: all 2s;

					p
					{	
						font-size: 18px;
						line-height: 27px;
						font-weight: 400px;
						color: @c_black;
					}
				}	
				
				div.more
				{
					position: absolute;
					height: 200px;
					bottom: 0;
					left: -274px;
					right: 0;
					background: -moz-linear-gradient(top,  rgba(241,241,241,0) 0%, rgba(241,241,241,0.02) 1%, rgba(241,241,241,1) 50%, rgba(241,241,241,1) 100%);
					background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(241,241,241,0)), color-stop(1%,rgba(241,241,241,0.02)), color-stop(50%,rgba(241,241,241,1)), color-stop(100%,rgba(241,241,241,1)));
					background: -webkit-linear-gradient(top,  rgba(241,241,241,0) 0%,rgba(241,241,241,0.02) 1%,rgba(241,241,241,1) 50%,rgba(241,241,241,1) 100%);
					background: -o-linear-gradient(top,  rgba(241,241,241,0) 0%,rgba(241,241,241,0.02) 1%,rgba(241,241,241,1) 50%,rgba(241,241,241,1) 100%);
					background: -ms-linear-gradient(top,  rgba(241,241,241,0) 0%,rgba(241,241,241,0.02) 1%,rgba(241,241,241,1) 50%,rgba(241,241,241,1) 100%);
					background: linear-gradient(to bottom,  rgba(241,241,241,0) 0%,rgba(241,241,241,0.02) 1%,rgba(241,241,241,1) 50%,rgba(241,241,241,1) 100%);
					filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00f1f1f1', endColorstr='#f1f1f1',GradientType=0 );
					
					&:after
					{
						position: absolute;
						display: block;
						content: "";
						height: 17px;
						left: 0;
						right: 0;
						bottom: 30px;
						background-image: url("../img/arrow-down.png");
						background-position: center center;
						background-repeat: no-repeat;
						z-index: 9;
						-webkit-transition: all 1s; /* Safari */
						transition: all 1s;
						cursor: pointer;
					}
				}
				
				&.active
				{
					z-index: 3;
					
					div.description
					{
					}
					
					div.more
					{
						background: none;
						
						&:after
						{
							-ms-transform: scale(1, -1); /* IE 9 */
							-webkit-transform: scale(1, -1); /* Safari */
							transform: scale(1, -1);
						}
					}
				}
			}
		}
		
		&#cytat
		{
			height: 447px;
			background: url("../img/cita-background.jpg") no-repeat center center;
			color: @c_white;
			
			p
			{
				margin: 0;
				padding-top: 170px;
				padding-bottom: 29px;
				font-size: 44px;
				line-height: 46px;
				font-weight: 400;
				font-style: italic;
			}
			
			span
			{
				font-size: 26.4px;
				font-weight: 400;
			}
		}
		
		&#o-szkole
		{
			p
			{
				width: 584px;
				margin: 0 auto;
				padding-top: 27px;
				text-align: left;
				color: @c_lightgrey;
				font-size: 18px;
				line-height: 27px;
				
				&:last-of-type
				{
					margin-bottom: 135px;
				}
			}
		}
		
		&#zapraszamy-do-zapisow
		{
			height: 962px;
			background: url("../img/invitation-background.jpg") no-repeat center center;
			background-attachment: fixed; 
			
			form 
			{
				padding-top: 24px;

				fieldset 
				{
					position: relative;
					border: none;
					width: 575px;
					margin: 0 auto;

					.input 
					{
						margin-bottom: 45px;
						
						input[type="text"] 
						{
							width: 100%;
							height: 60px;
							padding-left: 30px;
							padding-right: 10px;
							font-size: 18px;
							font-weight: 400;
							background: #ffffff;
							border: none;
							color: #525252;
							outline: none;
						}
						
						label.error {
							display: block;
							padding-top: 4px;
							color: #f00;
						}
					}

					.checkbox 
					{
						padding-bottom: 25px;
						position: relative;
						a 
						{
							color: #fff;
						}
					}
					div.classtype
					{
						text-align: left;
						margin-left: 30px;
						
						p
						{
							font-size: 18px;
							font-weight: 400;
							text-align: left;
							border: none;
							color: #525252;
							outline: none;
						}
						div.checkbox
						{
							display: inline-block;
							height: 50px;
							vertical-align: top;
							
							&:last-of-type
							{
								margin-left: 50px;
							}	

							label 
							{
								text-align: left;

								input[type="checkbox"] 
								{
									display: none;

									& + span 
									{
										position: relative;
										padding-left: 35px;
										text-align: left;
										color: #525252;
										font-size: 18px;
										font-weight: 400;
										-webkit-touch-callout: none;
										-webkit-user-select: none;
										-khtml-user-select: none;
										-moz-user-select: none;
										-ms-user-select: none;
										user-select: none;
										cursor: pointer;

										&:before 
										{
											content: "";
											position: absolute;
											width: 19px;
											height: 20px;
											left: 0;
											border: 1px solid @c_marsala;
										}
									}

									&:checked 
									{
										& + span 
										{
											&:before 
											{
												background: #ffffff;
												content: "✓";
												text-align: center;
												font-size: 14px;
												line-height: 20px;
											}
										}
									}
									
								}
								
								label.error {
									display: block;
									color: #f00;
									margin-top: 10px;
								}
							}
						}
					}	
					
					> div.submitbutton
					{
						position: relative;
						width: 181px;
						padding-top: 110px;
						margin: 0 auto;
						
						input[type="submit"] {
							display: inline-block;
							height: 61px;
							width: 100%;
							left: 647px;
							bottom: -150px;
							padding: 0 17px 0 17px;
							line-height: 36px;
							font-size: 24px;
							font-weight: 400;
							color: #fff;
							text-decoration: none;
							border: none;
							background: @c_marsala;
							background-repeat: no-repeat;
							background-position: 84px center;
							outline: none;
						}

						img.pointer
						{
							position: absolute;
							right: -10px;
							bottom: -30px;
						}
					}	
				}
			}
			
			.thanks {
				display: none;
				font-size: 30px;
				font-weight: 300;
				color: #4f4f4f;
				line-height: 40px;
			}
		}
		
		&#kontakt
		{
			position: relative;
			background: #b5c0c8;
			
			h2
			{
				color: @c_white;
				margin: 0;
			}
			
			ul
			{
				margin: 0;
				padding-top: 30px;
				padding-bottom: 110px;
				text-align: left;
				
				li
				{
					display: inline-block;
					padding-left: 56px;
					padding-top: 15px;
					color: @c_white;
					list-style: none;
					text-align: left;
					font-size: 16px;
					background: no-repeat left;
					vertical-align: top;
					
					a
					{
						color: @c_white;
						/*text-decoration: none;*/
						
						&:hover
						{
						color: @c_marsala;
						}	
					}
					
					&:nth-of-type(1)
					{
						display: inline-block;
						content: "";
						height: 43px;
						width: 182px;
						background: url("../img/letter.png") no-repeat left center;
					}
					
					&:nth-of-type(2)
					{
						display: inline-block;
						content: "";
						height: 43px;
						width: 156px;
						background: url("../img/phone.png") no-repeat left center;
					}
					
					&:nth-of-type(3)
					{
						display: inline-block;
						content: "";
						height: 43px;
						width: 352px;
						background: url("../img/addres.png") no-repeat left center;
					}
					
					&:nth-of-type(4)
					{
						display: inline-block;
						content: "";
						height: 43px;
						width: 260px;
						background: url("../img/addres.png") no-repeat left center;
					}
				}
			}
			
			> img
			{
				position: absolute;
				top: 234px;

				&.fb
				{
					right: 77px;
				}

				&.instagram
				{
					right: 24px;
				}
			}
		}
		
		&#mapa
		{
			iframe
			{
				display: inline-block;
				
				&:first-of-type
				{
					left: 0;
				}
			}
		}
	}
	
	footer
	{
		> p
		{
			margin: 100px auto;
			color: #b5c0c8;
			font-size: 14px;
			line-height: 27px;
			
		}
	}
}